//
//  Base
//  ---------------------------------------------

$baseDir: "../"; // Base directory path

//
// Media query
// ---------------------------------------------
// two variable used inside magento 2 to set media query 2 features:
// 1 - "common" options, different in emails and style_l
// 2 - "target" all, desktop or mobile devices
$media-common: true; // Sets whether to output common styles (true|false)
$media-target: 'all'; // Sets target device for styles output (all|desktop|mobile)

//
//  Typography
//  ---------------------------------------------

//  Fonts
$icons__font-path: '../fonts/Blank-Theme-Icons/Blank-Theme-Icons';
$icons__font-name: 'icons-blank-theme';

//  Color nesting
$panel__background-color: $color-gray-light0;
$border-color__base: $color-gray80;
$border-color__light: $color-gray82;



//  Headings
$h3__font-size: 18px;
$h3__font-weight: $font-weight__semibold;
$h3__margin-top: $indent__base;

//
//  Tables
//  ---------------------------------------------

$table-cell__padding-vertical: 11px;
$table-cell__hover__background-color: $panel__background-color;
$table-cell-stripped__background-color: $table-cell__hover__background-color;
$table-responsive-th__background-color: $table-cell-stripped__background-color;
$table-responsive-cell__padding: $indent__xs $indent__s;

//
//  Layout grid
//  ---------------------------------------------
//  Variables for layout columns
$layout-column__sidebar-width: 5;

//  Checkout columns width
$layout-column-checkout__width-left: 3;


//
// header
//
$header-icons-color: $color-gray56;
$header-icons-color-hover: $color-gray20;

//
//  Navigation
//  ---------------------------------------------

$navigation__background: $c-tomassen-blue;
$navigation-level0-item__active__color: $secondary__color;
$submenu-item__active__color: $navigation-level0-item__active__color;
$navigation-level0-item__color: $color-gray34;
$navigation-level0-item__text-decoration: none;
$navigation-desktop-level0-item__text-decoration: none;
$navigation-desktop-level0-item__color: $c-tomassen-grey-light;
$navigation-desktop-level0-item__hover__color: $c-tomassen-grey-light;

//  Desktop navigation
$navigation-desktop-level0-item__line-height: 47px;
$submenu-desktop__font-weight: $font-weight__regular;
$submenu-desktop-item__active__color: $c-tomassen-grey-light;

//  Tabs
$tab-control__font-weight: $font-weight__regular;
$tab-control__height: $indent__base * 2;
$tab-control__margin-right: -1px;
$tab-control__padding-top: 1px;
$tab-control__padding-right: 35px;

//  Tabs content
$tab-content__padding-top: 35px;
$tab-content__padding-right: $tab-control__padding-right;

//
//  Accordion
//  ---------------------------------------------

$accordion-control__font-family: inherit;
$accordion-control__font-size: $font-size__base;
$accordion-control__font-style: inherit;
$accordion-control__font-weight: $font-weight__semibold;
$accordion-control__border-right: inherit;
$accordion-control__border-bottom: inherit;
$accordion-control__border-left: inherit;
$accordion-control__background-color: inherit;
$accordion-control__color: $primary__color;
$accordion-control__hover__background-color: inherit;
$accordion-control__hover__color: $accordion-control__color;
$accordion-control__active__background-color: inherit;
$accordion-control__active__color: $accordion-control__color;
$accordion-control__margin-bottom: 0;
$accordion-control__padding-right: $indent__s;
$accordion-control__padding-left: $indent__s;
$accordion-content__border: inherit;
$accordion-content__margin: 0;
$accordion-content__padding: $indent__s $indent__s $indent__l;

//
//  Icons
//  ---------------------------------------------
//

$icon-success: '\e60e';
$icon-error: '\e61f';
$icon-edit: '\e606';
$icon-print: '\e624';
$icon-star-empty: '\e625';
$icon-download: '\e626';
$icon-private: '\e629';
$icon-present: '\e62a';
$icon-gift-registry: '\e62b';

$icon-calendar__font-size: 23px;
$icon-wishlist-empty: inherit;
$icon-comment-reflected: inherit;
$icon-compare-empty: '\e61f';
$icon-arrow-up-thin: inherit;
$icon-arrow-right-thin: inherit;
$icon-arrow-left-thin: inherit;
$icon-arrow-down-thin: inherit;
$icon-wishlist-empty: '\e601';

//
//  Messages
//  ---------------------------------------------

$message__padding: 12px $indent__base 12px $indent__m;
$message__line-height: inherit;
$message-icon__font-size: 24px;
$message-icon__inner-padding-left: 45px;
$message-icon__top: 22px;
$message-error-icon: $icon-error;
$message-success-icon: $icon-success;

//
//  Loaders
//  ---------------------------------------------

$loader-overlay__background-color: rgba(255, 255, 255, 0.7);
$loader-overlay__z-index: 9999;

//
//  Forms
//  ---------------------------------------------

//  Form fieldset
$form-fieldset-legend__font-size: 18px;
$form-fieldset-legend__font-weight: $font-weight__light;
$form-fieldset-legend__margin: 0 0 $indent__base;
$form-fieldset-legend__padding: 0 0 $indent__s;
$form-fieldset-legend__width: 100%;

//  Form field
$form-field-type-revert: inherit;
$form-field__additional-vertical-indent: $indent__s;

//  Form field label
$form-field-label__font-weight: $font-weight__semibold;
$form-field-type-label-block__margin: 0 0 8px;
$form-field-type-label-inline__width: 25.8%;

//  Form field note icon
$form-field-note-icon-font__content: inherit;
$form-field-note__color: inherit;

$select__background: $color-white;
$form-field--vertical-indent: $indent__base;

//
//  Pager
//  ---------------------------------------------

$pager__font-weight: $font-weight__regular;
$pager__line-height: $indent__l;
$pager-item__margin: 0 8px 0 0;
$pager-item__padding: 0 $indent__xs;
$pager-item__display: inline-block;

//  Pager current page
$pager-current__background: $color-gray90;
$pager-reset-spaces: true;  // Reset spaces between inline-block elements

//  Pager link.action
$pager-icon__font-margin: 0;
$pager-icon__font-size: 16px;

//  Pager link.action visited
$pager-action__visited__color: $text__color__muted;

//  Pager link.action hover
$pager-action__hover__color: $text__color__muted;

//  Pager link.action active
$pager-action__active__color: $text__color__muted;

//
//  Rating
//  ---------------------------------------------

$rating-icon__font-size: 28px;
$rating-icon__letter-spacing: -9px;
$rating-icon__active__color: $active__color;

//  Split dropdown
$dropdown-split-actions__padding: 0 $indent__xs;
$dropdown-split-toggle__actions__padding: inherit;
$dropdown-list-item__hover: $color-gray91;
$dropdown-list__z-index: 100;


//  Actions-toolbar
$actions-toolbar-actions__position: justify;
$actions-toolbar-actions__reverse: false;
$actions-toolbar-actions-primary__margin: 0 15px 0 0;

//
//  Breadcrumbs
//  ---------------------------------------------

$breadcrumbs__container-margin: 0 0 15px;
$breadcrumbs-icon__font-size: 10px;
$breadcrumbs-icon__font-margin: 0 $indent__s;

//
//  Popups
//  ---------------------------------------------

$popup__border: 1px solid $color-gray68;
$popup-title-headings__level: h2;



//  Window overlay
$overlay__background: $color-gray55;
$overlay__opacity: 0.7;
$overlay__opacity-old: 70;

$gallery-thumb-border-color-active: $active__color;

//  Checkout tooltip
$checkout-tooltip-icon-arrow__font-size: 10px;
$checkout-tooltip-icon__font-size: 21px;

$checkout-tooltip-content-mobile__right: -($indent__s);
$checkout-tooltip-content-mobile__top: 30px + $checkout-tooltip-icon-arrow__font-size;
$checkout-tooltip-content__background-color: $color-gray-light01;
$checkout-tooltip-content__padding: 12px;
$checkout-tooltip-content__border-width: 1px;

$checkout-tooltip-content__border-color: $color-gray60;
$checkout-tooltip-content__font-size: $font-size__base;
$checkout-tooltip-content__width: 270px;
$checkout-tooltip-content__active__border-color: darken($checkout-tooltip-content__border-color, 20%);
$checkout-tooltip-icon-arrow__left: -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width);
$checkout-billing-address-details__line-height: 27px;


// Checkout Payment Option icon
$checkout-payment-option-title-icon__font-size: 14px;
$checkout-payment-option-title-icon__line-height: 16px;
$checkout-payment-option-title-icon__margin: 0 0 0 $indent__s;

// Checkout Sidebar Shipping Information
$checkout-sidebar-shipping-information-edit-icon__content: $icon-edit;
$checkout-sidebar-shipping-information-edit-icon__font-size: 18px;
$checkout-sidebar-shipping-information-edit-icon__line-height: 20px;
$checkout-sidebar-shipping-information-edit-icon__top: $indent__s;

// Checkout
$checkout-step-title__padding: $indent__s;
$checkout-step-title__border: $border-width__base solid $color-gray80;
$checkout-step-title__font-size: 26px;
$checkout-step-title__font-weight: $font-weight__light;
$checkout-sidebar__columns: 8;
$checkout-shipping-address__max-width: 500px;

//  Typography
//  ---------------------------------------------

//  Fonts
$font-family-name__base: 'Open Sans';
$font-family__base: $font-family-name__base, $font-family__sans-serif;

$font-family-name__secondary: 'Futura Extra Bold';
$font-family__secondary: $font-family-name__secondary, $font-family__base;

//  Colors nesting
$page__background-color: $color-white;
$copyright__background-color: $color-white;

//
//  Sidebar
//  ---------------------------------------------

$sidebar__background-color: $color-white-smoke;
// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background


// variables missing in extend.scss
// addto color
$addto-color: $primary__color;
$addto-hover-color: $link__hover__color;

// mobile cart padding
$mobile-cart-padding: 15px;

// account title border
$account-title-border-color: $border-color__base;

// price color
$cart-price-color: $primary__color;
$price-color: $color-gray34;
$price-size: 22px;
$price-size-desktop: 36px;

$collapsible-nav-background: $sidebar__background-color;
$collapsible-nav-item-hover: $color-gray91;

//
//  Minicart
//  ---------------------------------------------

$minicart-icons-color                                         : $header-icons-color !default;
$minicart-icons-color-hover                                   : $header-icons-color-hover !default;