.block.newsletter {
    .form.subscribe {
    }

    .fieldset {
    }

    .field {
        margin: 0;
        float: left;
        width: calc(100% - 150px);

        @include min-screen($screen__m) {
            width: calc(100% - 250px);
        }
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        float: left;
        width: 150px;

        .subscribe {
            width: 100%;
            line-height: 26px;
            font-weight: normal;

            &:hover {
                background-color: darken($c-tomassen-blue, 10%);
            }
        }
    }

    .facebook {
        float: right;

        margin: 20px 0 0 0;
        @include min-screen($screen__m) {
            margin: 0;
        }
    }

}