// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
$legend-border-bottom: 1px solid $color-gray-light6;
$form-field__vertical-indent__desktop: 15px;
$form-calendar-icon__color: $primary__color;
$form-field__vertical-indent: $indent__base;
//
//    Common
//--------------------------------------
.fieldset {
    @include lib-form-fieldset();

    &:last-child {
        margin-bottom: 0;
    }

    & > .field,
    & > .fields > .field {
        @include lib-form-field();

        &.no-label {
            > .label {
                @include lib-visually-hidden();
            }
        }

        &.choice {
            .label {
                font-weight: normal;
                display: inline;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    span {
        margin-right: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {
    &.date {
        .time-picker {
            white-space: nowrap;
            margin-top: $indent__s;
            display: inline-block;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

@mixin select-styling() {
    appearance: none;
    height: 42px;
    background: url('../images/svg/select-bg.svg') no-repeat right 8px center $c-tomassen-grey-lighter;
    background-size: 10px 5px;
    padding-right: 25px;
    text-indent: .01em;

    &::-ms-expand {
        display: none;
    }

    .lt-ie10 & {
        background-image: none;
        padding-right: 4px;
    }
}

select {
    @include select-styling();
}

div.mage-error[generated] {
    margin-top: 7px;
}
// TEMP
.field .tooltip {
    @include lib-tooltip(right);

    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

.hasDatepicker {
    + .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content    : $icon-calendar,
            $_icon-font-color      : $primary__color__lighter,
            $_icon-font-size       : $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display    : inline-block,
            $_icon-font-text-hide  : true
        );
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

//
//    Sidebar forms
//--------------------------------------
.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//    Tooltip icon
//--------------------------------------
.tooltip-info {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid $c-tomassen-blue-light;
    color: $c-tomassen-blue-light;
    text-align: center;
    line-height: 16px;
    border-radius: 50%;
    margin-left: 5px;
}

//
//    Desktop
//--------------------------------------
@include min-screen($screen__m) {
    .fieldset {
        .legend {
            @extend .abs-margin-for-forms-desktop;
        }

        > .field {
            @include lib-form-field-type-revert();
            margin: 0 0 $form-field__vertical-indent__desktop;
        }
    }
}
