// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Tomassen specific colors
//  ---------------------------------------------
$c-tomassen-white:          #ffffff;
$c-tomassen-black:          #000000;
$c-tomassen-grey-dark:      #323232;
$c-tomassen-grey-medium:    #676767;
$c-tomassen-grey:           #868585;
$c-tomassen-grey-medlight:  #ababab;
$c-tomassen-grey-light:     #e2dfdc;
$c-tomassen-grey-lighter:   #f3f1ef;
$c-tomassen-blue:           #09486b;
$c-tomassen-blue-light:     #3087b6;
$c-tomassen-red:            #eb1a21;
$c-tomassen-green:          #6fb500;
$c-tomassen-orange:         #fd9500;


//
//  Color variables
//  _____________________________________________

$color-white: #fff;
$color-black: #000;

$color-gray19: #303030;
$color-gray20: #333;
$color-gray34: #575757;
$color-gray40: #666;
$color-gray52: #858585;
$color-gray55: #8c8c8c;
$color-gray56: #8f8f8f;
$color-gray60: #999;
$color-gray62: #9e9e9e;
$color-gray64: #a3a3a3;
$color-gray68: #adadad;
$color-gray76: #c2c2c2;
$color-gray78: #c7c7c7;
$color-gray79: #c9c9c9;
$color-gray80: #ccc;
$color-gray82: #d1d1d1;
$color-gray89: #e3e3e3;
$color-gray90: #e5e5e5;
$color-gray91: #e8e8e8;
$color-gray92: #ebebeb;
$color-gray94: #f0f0f0;
$color-gray95: #f2f2f2;
$color-white-smoke: #f5f5f5;
$color-white-dark-smoke: #efefef;
$color-white-fog: #f8f8f8;

$color-gray-light0: #f6f6f6;
$color-gray-light01: #f4f4f4;
$color-gray-light1: #e5efe5;
$color-gray-light2: #bbb;
$color-gray-light3: #aeaeae;
$color-gray-light4: #cecece;
$color-gray-light5: #c1c1c1;
$color-gray-light6: #c5c5c5;

$color-gray-middle1: #e4e4e4;
$color-gray-middle2: #c6c6c6;
$color-gray-middle3: #7e807e;

$color-gray-darken0: #eee;
$color-gray-darken1: #e2e2e2;
$color-gray-darken2: #cdcdcd;
$color-gray-darken3: #555;
$color-gray-darken4: #494949;

$color-red9: $c-tomassen-red;
$color-red10: lighten($c-tomassen-red, 10%);
$color-red11: darken($c-tomassen-red, 20%);
$color-red12: darken($c-tomassen-red, 10%);

$color-orange-red1: #ff5501;
$color-orange-red2: #ff5601;
$color-orange-red3: #ff5700;
$color-orange-red4: #fc5e10;

$color-dark-green1: #006400;

$color-blue1: $c-tomassen-blue;
$color-blue2: $c-tomassen-blue-light;
$color-sky-blue1: #68a8e0;

$color-pink1: #fae5e5;
$color-dark-pink1: #800080;

$color-brownie1: #6f4400;
$color-brownie-light1: #c07600;

$color-yellow-light1: #fdf0d5;
$color-yellow-light2: #ffee9c;
$color-yellow-light3: #d6ca8e;

//
//  Color nesting
//  ---------------------------------------------
$primary__color: $c-tomassen-black;
$primary__color__dark: darken($primary__color, 35%); // #000
$primary__color__darker: darken($primary__color, 13.5%); // #111
$primary__color__lighter: lighten($primary__color, 29%); // #7d7d7d
$primary__color__light: lighten($primary__color, 45%); // #a6a6a6

$secondary__color: $c-tomassen-grey-light;
$secondary__color__light: lighten($secondary__color, 5%);

$page__background-color: $color-white;
$panel__background-color: $c-tomassen-grey-light;

$active__color: $c-tomassen-orange;
$error__color: $color-red10;
