.fotorama__arr__arr {
    .fotorama__arr & {
        width: $size-fotorama-block;
        height: $size-fotorama-block;
        text-align: center;
        @include fotorama-abs-center();

        &::before {
            content: '';
            display: block;
            font-size: 70px;
            line-height: $size-fotorama-block;
            color: $c-tomassen-grey;
            font-weight: lighter;
        }

        .ie9 & {
            margin: (-$size-fotorama-block / 2) 0 0 (-$size-fotorama-block / 2);
        }
    }

    .fotorama__arr--prev & {
        &::before {
            content: '❬';
        }
    }

    .fotorama__arr--next & {
        &::before {
            content: '❭';
        }
    }
}

.fotorama__fullscreen-icon {
    background: none;
    text-align: center;

    &::before {
        content: '×';
        display: inline-block;
        font-size: 70px;
        line-height: $size-fotorama-block;
        color: $c-tomassen-grey;
        font-weight: lighter;
    }
}

.fotorama__arr {
    @include max-screen($screen__m) {
        width: 40px;
    }
}