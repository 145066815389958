.page-product-grouped .product-info-price .price-box {
    display: none;
}

.table.grouped {
    @include lib-table-bordered($_table_type: horizontal);
    font-size: $font-size__m;

    @include max-screen($screen__s) {
        font-size: $font-size__s;
    }

    & > thead > tr > th {
        border-bottom: 0;
    }

    & > tbody>tr>td,
    & > tbody>tr>th,
    & > tfoot>tr>td,
    & > tfoot>tr>th,
    & > thead>tr>td,
    & > thead>tr>th {
        padding: 0 5px;
    }

    tbody tr:nth-child(odd) {
        background: $c-tomassen-white;
    }

    td.col {
        vertical-align: middle;
    }

    th.weight {
        text-align: right;
    }

    .product-item-name {
        font-weight: $font-weight__regular;
        padding: 0;
        height: 30px;
        line-height: 30px;
        margin: 0;
        font-size: $font-size__m;
        //width: calc(100% - 70px);
        //float: left;

        @include max-screen($screen__s) {
            font-size: $font-size__s;
            line-height: 15px;
            height: auto;
            display: table-cell;
            vertical-align: middle;
        }
    }

    th.price-box-header {
        text-align: right;
    }

    .price-box {
        width: 70px;
        float: right;
        text-align: right;
        margin: 0;
        height: 30px;
        line-height: 30px;
        padding: 0;

        .price {
            font-size: $font-size__m;
        }
    }

    .total-row {
        .price-box {
            padding: 0;
            margin-top: -5px;
        }
    }

    .control.qty {
        @extend .abs-input-qty;
        padding: 2px 0;

        input {
            height: 26px;
        }
    }


    .stock.unavailable {
        font-weight: normal;
        margin: 0;
        text-transform: none;
        white-space: nowrap;
    }

    .row-tier-price {
        td {
            border-top: 0;
        }

        .prices-tier {
            margin: 0 -10px 10px;
        }
    }

    .product-item-weight {
        width: 70px;
        float: right;
        text-align: right;
        margin: 0;
        height: 30px;
        line-height: 30px;
        padding: 0;

        @include max-screen($screen__s) {
            width: 50px;
            padding: 0;
            height: auto;
        }
    }
}




//change column widths for grouped products

@include min-screen($screen__m) {
    .page-product-grouped {
        .product-info-main {
            width: 57%;
        }
        .product.media {
            width: 40%;
        }
    }
}
