//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base;
$checkout-wrapper__columns            : 8;

$checkout-step-title__border          : $border-width__base solid $color-gray80;
$checkout-step-title__font-size       : 26px;
$checkout-step-title__font-weight     : $font-weight__light;
$checkout-step-title__padding         : $indent__s;

$checkout-step-title-mobile__font-size: 18px;

//
//  Common
//  _____________________________________________

.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        //@extend .abs-visually-hidden;
        margin-top: 20px;
    }
}
.checkout-index-index {
    .logo {
        margin-top: 0;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @include lib-special-heading($c-tomassen-blue, $font-size__xl);
        padding: 5px 0;
        margin: 5px 0;
        border-bottom: 1px solid $c-tomassen-grey-light;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.field-tooltip {
    .tooltip-label {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }
}
