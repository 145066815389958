// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//  Variables
//  ---------------------------------------------
$product-name-link__color: $text__color;
$product-name-link__color__active: $text__color;
$product-name-link__color__hover: $text__color;
$product-name-link__color__visited: $text__color;
$product-name-link__text-decoration: none;
$product-name-link__text-decoration__active: $link__hover__text-decoration;
$product-name-link__text-decoration__hover: $link__hover__text-decoration;
$product-name-link__text-decoration__visited: $link__hover__text-decoration;
$product-item__hover__background-color: $color-white;
$product-item__hover__box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, .3);
//
//
//  Common
//  _____________________________________________
//  Product Lists
.products {
    margin: $indent__l 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        margin-bottom: 20px !important;

        .products-grid & {
            display: inline-block;
            width: 50%;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
            padding: 0 20px;
            color: $c-tomassen-blue;
            font-weight: bold;
            height: 58px;
            overflow: hidden;

            @include max-screen($screen__s) {
                padding: 0 10px;
                height: 45px;
            }

            a {
                color: $c-tomassen-blue;
                font-size: $font-size__ml;
                //letter-spacing: .5px;
                line-height: 18px;

                @include max-screen($screen__s) {
                    font-size: $font-size__m;
                    line-height: 15px;
                }
            }
        }

        &-info {
            max-width: 100%;
            background: #ffffff;
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, .15);
            transition: all .2s ease-out;

            .page-products & {
                width: 240px;
            }
        }

        &:hover .product-item-info {
            box-shadow: 0 0 8px 1px rgba(0, 0, 0, .20);
        }

        .actions-primary {
            width: 100%;
            display: block;
            overflow: hidden;

            .primary {
                float: left;
                text-align: center;
                border: 0;
                font-weight: 300;
                padding: 10px 15px;
                height: 40px;
                line-height: 20px;

                @include min-screen($screen__m) {
                    line-height: 30px;
                    height: 50px;
                }
            }

            .tocart {
                width: 100%;
                background: $c-tomassen-blue-light;
                font-size: $font-size__m;
                @include min-screen($screen__m) {
                    width: calc(100% - 96px);
                }
            }

            .moreinfo {
                background: $c-tomassen-blue;
                width: 100%;
                font-size: $font-size__m;
                @include min-screen($screen__m) {
                    width: 96px;
                }
            }
        }

        .actions-secondary {
            display: none;
        }

        &-actions {
            //display: none;

            .actions-secondary {
                display: none;

                & > .action {
                    @extend .abs-actions-addto-gridlist;

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: 10px 0 0;
            padding: 0 20px;
            overflow: hidden;
            max-height: 45px;

            @include max-screen($screen__s) {
                padding: 0 10px;
            }

            .special-price .price,
            .minimal-price .price,
            .price {
                font-weight: $font-weight__bold;
                float: left;
                margin-right: 10px;
                font-size: $font-size__l;
                line-height: 25px;

                @include max-screen($screen__s) {
                    font-size: $font-size__ml;
                }
            }

            .special-price,
            .minimal-price {
                float: left;
                margin: 0;
            }

            .old-price {
                .price {
                    font-size: $font-size__base;
                    color: $c-tomassen-grey;
                    font-weight: normal;
                    text-decoration: line-through;
                    line-height: 25px;
                }
            }

            .price-box {
                margin: 0;
                padding: 0;
            }

            .price-label {
                display: none;
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .price-text {
            clear: left;
            display: block;
            width: 100%;
            font-style: italic;
            padding: 0 20px 20px;
            color: $c-tomassen-grey-dark;
            font-size: $font-size__base;
            font-weight: bold;
        }

        .special-price,
        .minimal-price {

            .price-wrapper {
                //display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            white-space: nowrap;
            display: block;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;
        }

        &-item {
            padding-left: $indent__base;
        }
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: 5px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                width: 1%;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                display: table-cell;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }
            }
        }
    }

    .products-grid .product-item,
    .page-layout-1column .products-grid .product-item,
    .page-layout-3columns .products-grid .product-item,
    .page-products .products-grid .product-item,
    .page-products.page-layout-1column .products-grid .product-item
    .page-products.page-layout-3columns .products-grid .product-item {
        width: 33.3333%;
    }
}
//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 20%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.233%) / 3);
                padding: 0;
                width: 23.233%;

                &:nth-child(4n+1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    width: 20%;
                }
            }
        }
    }
}
