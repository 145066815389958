// Extends Magento_Theme/web/css/source/_module.scss

//Homepage
.cms-index-index {
    .block-products-list {
        margin-top: 30px;
        margin-bottom: 10px;

        .block-title {
            @include lib-special-heading($c-tomassen-blue, $font-size__xl);
            position: relative;
            width: 100%;
            text-align: center;

            strong {
                background: #f6f5f3;
                padding: 0 20px;
                display: inline-block;
            }

            &::before {
                content: '';
                height: 2px;
                width: 100%;
                background: $c-tomassen-blue;
                position: absolute;
                top: 15px;
                left: 0;
                z-index: -1;
            }
        }
    }

    .category-blocks {
        overflow: hidden;
        margin-bottom: 30px;

        .category-block {
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            display: block;

            @include min-screen($screen__m) {
                width: calc((100% - 40px) / 3);
                margin-right: 20px;
                float: left;

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }

            &::before {
                content: '';
                width: calc(100% - 20px);
                height: calc(100% - 20px);
                position: absolute;
                top: 10px;
                left: 10px;
                background: $c-tomassen-white;
                opacity: .4;
                transition: transform .3s ease, opacity .3s ease;
            }

            &:hover {
                &::before {
                    transform: scale(1.1);
                    opacity: .6;
                }
            }

            img {
                display: block;
                width: 100%;
            }

            h4 {
                @include lib-special-heading($c-tomassen-blue, $font-size__xl);
                text-align: center;
                position: absolute;
                width: 100%;
                top: calc(50% - 10px);
            }
        }
    }

    .home-bottom-left {
        width: 100%;

        &::after {
            content: '';
            display: table;
            clear: both;
        }

        @include min-screen($screen__l) {
            float: left;
            width: 512px;
            margin-top: 10px;
        }
    }

    .home-video,
    .home-folder {
        width: 100%;
        height: auto;
        margin-bottom: 20px;

        iframe {
            max-width: 100%;
        }

        @include min-screen($screen__m) {
            float: left;
            width: 48%;
        }

        @include min-screen($screen__l) {
            float: none;
            width: 100%;
        }
    }

    .home-folder {
        box-sizing: border-box;
        padding: 60px 30px 70px;
        text-align: center;
        background: $c-tomassen-blue;
        border: 10px solid $c-tomassen-blue-light;
        color: $c-tomassen-white;
        transition: border .3s ease;

        @include min-screen($screen__m) {
            float: right;
        }

        &:hover {
            border-color: $c-tomassen-blue;
        }

        h3 {
            @include lib-special-heading($c-tomassen-white, $font-size__l);
        }

        a {
            color: $c-tomassen-white;
            text-decoration: underline;
        }
    }

    .home-content {
        width: 100%;
        margin-bottom: 30px;

        h2 {
            margin-bottom: 10px;
            @include lib-special-heading($c-tomassen-red, $font-size__xl);
        }

        @include min-screen($screen__l) {
            float: right;
            width: calc(100% - 542px);
        }
    }
}

//Bedrijfslogos
.logos-bedrijven {
    li {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 5px;
        box-sizing: border-box;
        width: 50%;
        float: left;

        &:nth-child(odd) {
            clear: left;
        }

        @include min-screen($screen__l) {
            width: 33.333%;
            float: left;

            &:nth-child(odd) {
                clear: none;
            }

            &:nth-child(3n + 1) {
                clear: left;
            }
        }

        a {
            display: block;

            img {
                max-width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}