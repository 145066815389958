// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */
//
//    Common
//--------------------------------------
.block-category-link,
.block-product-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}
.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}
.block.widget {
    .product-item-info {
        width: auto;
    }
    .pager {
        padding: 0;
        .toolbar-amount {
            float: none;
            font-size: 12px;
        }
        .pages-item-previous {
            padding-left: 0;
        }
        .pages-item-next {
            position: relative;
        }
        .items {
            white-space: nowrap;
        }
    }
}
//
//    Mobile
//--------------------------------------
@include min-screen($screen__m) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 33.3333%;
    }
}
//
//    Desktop
//--------------------------------------
@include min-screen($screen__l) {
    .block.widget .products-grid .product-item {
        width: 33.3333%;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        width: 23.3%;
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 50%;
    }
}

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }
        .pages-item-next {
            padding: 0;
            .action {
                margin: 0;
            }
        }
    }
}
