// /**
//  * Copyright © 2015 Magento. All rights reserved.
//  * See COPYING.txt for license details.
//  */

@import '_extend.scss';

//
//  Theme variables
//  _____________________________________________

//
//  Messages
//  ---------------------------------------------

$messages-global-note: (
    color: $text__color,
    background: $color-yellow-light2,
    link-color: $link__color,
    link-color-hover: $link__hover__color,
    link-color-active: $link__active__color,
    border-color: $color-yellow-light3
);

$messages-global-caution: (
    color: $color-white,
    background: $color-red9,
    link-color: $link__color,
    link-color-hover: $link__hover__color,
    link-color-active: $link__active__color,
    border-color: none
);

$message-global-note__border-color: $color-yellow-light3;
$message-global-caution__border-color: none;

$header__background-color: $c-tomassen-white;
$header-panel__background-color: $c-tomassen-grey-lighter;
$header-panel__text-color: $c-tomassen-grey-medium;
$header-icons-color: $c-tomassen-grey-medium;
$header-icons-color-hover: $c-tomassen-grey-medium;
$customer-welcome__z-index: $dropdown-list__z-index + 1;

$addto-color: $color-gray60;
$addto-hover-color: $primary__color;
$minicart-icons-color: $header-icons-color;
$minicart-icons-color-hover: $header-icons-color-hover;

$price-color: $color-gray34;
$price-size: 22px;
$price-size-desktop: 36px;

$button__shadow: inset 0 1px 0 0 rgba(255, 255, 255, 1), inset 0 -1px 0 0 fade-out($border-color__base, 0.3); //  Used for secondary button and catalog toolbar controls
$button__shadow-active: inset 0 1px 0 0 fade-out($border-color__base, 0.8), inset 0 -1px 0 0 fade-out($border-color__base, 0.3); //  Used for secondary button and catalog toolbar controls

$h1__margin-bottom__desktop: $indent__xl;

//
//  Footer
//  ---------------------------------------------

$footer__background-color: $c-tomassen-blue;
$footer-links-separator-border-color: $color-gray-light4;
$footer-links-color: $c-tomassen-white;
$footer-links-color-hover: $c-tomassen-white;
$footer-links-color-current: $c-tomassen-white;

//
//  Layered navigation
//  ---------------------------------------------

$filter-title-background-color: $color-gray94;
$filter-link: $color-gray-darken4;
$filter-link-hover: darken($filter-link, 30%);
$filter-quantity: $color-gray52;

//
//  Common
//  _____________________________________________


body {
    background-color: $page__background-color;
    background-image: url('../images/bkg.jpg');
}

//  ToDo UI: move it directly to .page-wrapper (now it doesn't appear in mediaquery cuz of size of css)
.page-wrapper {
    .ie9 & {
        background-color: $page__background-color;
        min-height: 0;
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    border-top: 5px solid $c-tomassen-red;
    margin-bottom: 5px $indent__base;
    background: $header__background-color;

    .panel.wrapper {
        color: $header-panel__text-color;
    }

    .kiyoh-header {
        float: right;
        margin-top: -20px;

        @include max-screen(767px) {
            position: absolute;
            margin-top: 0;
            top: 0;
            right: 120px;
            overflow: hidden;
            transform: scale(0.8);

            iframe {
                margin-top: -5px;
            }
        }

        @include max-screen($screen__xs) {
            margin-top: 0;
            right: 70px;
            transform: scale(0.7);
            height: 60px;
        }

        img {
            max-width: 100px;

            @include max-screen($screen__m) {
                display: none;
            }
        }
    }
}

.header.panel {
    & > .header.links {
        @include lib-list-inline();
        float: right;
        font-size: 0;
        margin-left: auto;
        margin-right: $indent__base;

        & > li {
            font-size: $font-size__base;
            margin: 0 0 0 15px;

            & > a {
                @include lib-link(
                    $_link-color                  : $link__color,
                    $_link-text-decoration        : none,
                    $_link-color-visited          : $link__visited__color,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover            : $link__hover__color,
                    $_link-text-decoration-hover  : underline,
                    $_link-color-active           : $link__active__color,
                    $_link-text-decoration-active : underline
                );
            }
        }
    }
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s 50px;
    max-width: 50%;
    position: relative;
    z-index: 5;

    @include max-screen($screen__xs) {
        max-width: calc(100% - 180px);
        width: 200px;
    }

    img {
        display: block;
        max-height: 48px;
        max-width: 100%;
        width: auto;
    }

    .page-print & {
        display: inline-block;
    }
}

.page-title {
    @include lib-special-heading($c-tomassen-blue, $font-size__title);

    @include max-screen($screen__s) {
        font-size: $font-size__xl;
    }
}

.page-main {
    & > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.breadcrumbs {
    display: none;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    margin-top: $indent__m;
    background: $footer__background-color;
}

.footer {
    &.newsletter {
        background: $c-tomassen-blue-light;
        text-align: center;

        @include min-screen($screen__m) {
            text-align: left;
        }

        .newsletter-content {
            width: 100%;
            color: $c-tomassen-white;

            @include min-screen($screen__m) {
                width: 490px;
                float: left;
            }

            h3 {
                @include lib-special-heading($c-tomassen-white, $font-size__l);
            }

            p {
                font-style: italic;
                font-family: $font-family__serif;
            }
        }

       .block.newsletter {
           width: 100%;

           @include min-screen($screen__m) {
               width: calc(100% - 190px);
               max-width: 555px;
               float: left;
               margin-top: 10px;
               margin-bottom: 0;
           }
       }
    }

    &.content {
        padding: 30px;
        border: none;

        &::after {
            content:'';
            display: table;
            clear: both;
        }

        .links {
            width: 100%;
            color: $c-tomassen-white;
            line-height: 2;
            margin-bottom: 20px;

            @include min-screen($screen__m) {
                float: left;
                margin-bottom: 0;
            }

            &-info {
                @include min-screen($screen__m) {
                    width: 20%;
                }
            }

            &-products {
                @include min-screen($screen__m) {
                    width: 30%;

                    ul {
                        float: left;
                        width: 50%;
                        padding: 0;
                    }
                }
            }

            &.block-times {
                @include min-screen($screen__m) {
                    width: 30%;
                }
            }

            &.block-contact {
                @include min-screen($screen__m) {
                    width: 20%;
                }
            }

            h3 {
                @include lib-special-heading($c-tomassen-white, $font-size__l);
            }

            ul {
                margin: 0;
                padding: 0;
            }

            li {
                border: none;
                margin: 0;
                list-style-type: none;
            }
            a {
                display: block;
                padding: 0;
                line-height: 2;
                @include lib-link(
                    $_link-color                  : $footer-links-color,
                    $_link-text-decoration        : none,
                    $_link-color-visited          : $footer-links-color,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover            : $footer-links-color-hover,
                    $_link-text-decoration-hover  : underline,
                    $_link-color-active           : $footer-links-color-hover,
                    $_link-text-decoration-active : underline
                );
            }
        }
    }

    .kiyoh-shop-snippets {
        max-width: 100%;

        .kiyoh-schema {
            float: left;
        }

        .rating-box {
            margin-left: 0;
        }

        a {
            color: $c-tomassen-white;
        }
    }

}

.copyright {
    background-color: $copyright__background-color;
    color: $c-tomassen-grey;
    display: block;
    padding: 20px 0;
    text-align: center;
}

.page-header,
.page-footer {
    .switcher {
        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding           : 0,
                $_dropdown-list-item-padding         : 0,
                $_dropdown-toggle-icon-content       : $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide                : true,
                $_icon-font-size                     : 10px,
                $_icon-font-line-height              : 22px,
                $_dropdown-list-min-width            : 160px
            );
            display: inline-block;
            ul.dropdown {
                a {
                    @include lib-link(
                        $_link-color                  : $color-gray20,
                        $_link-text-decoration        : none,
                        $_link-color-visited          : $color-gray20,
                        $_link-text-decoration-visited: none,
                        $_link-color-hover            : $color-gray20,
                        $_link-text-decoration-hover  : none,
                        $_link-color-active           : $color-gray20,
                        $_link-text-decoration-active : none
                    );
                    display: block;
                    padding: 8px;
                }
            }
        }

        strong {
            font-weight: $font-weight__regular;
        }

        .label {
            @extend .abs-visually-hidden;
        }
    }
}


//
//  Navigation
//  ---------------------------------------------

@include max-screen($screen__m) {
    .block-search .label {
        float: right;
        margin-right: 10px;
        margin-top: 2px;

        &::before {
            color: $c-tomassen-blue-light;
            font-size: 45px;
        }
    }

    .minicart-wrapper .action.showcart {
        margin-top: 3px;
    }

    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-open .nav-toggle:after {
        display: none;
    }

    .nav-toggle {
        top: 6px;

        &::before {
            color: $c-tomassen-blue;
            font-size: 40px;
        }
    }

    .nav-sections .section-item-title {
        display: none;
    }

    .nav-sections .switcher-trigger strong:after {
        font-size: 16px;
        right: -25px;
        top: 2px;
    }

    .navigation .parent .level-top:after {
        font-size: 16px;
        right: 10px;
        top: 7px;
    }

    .page-header {
        margin-bottom: 20px;
    }

    .page-footer .footer.content {
        .switcher-store {
            background: $footer__background-color;
            font-size: 16px;
            margin: 0 -$layout__width-xs-indent;

            .switcher-options {
                display: block;

                ul.dropdown {
                    background: none;
                    border: 0;
                    box-shadow: none;
                    margin: 0;
                    position: relative;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                .switcher-trigger,
                .switcher-option {
                    border-top: 1px solid $footer-links-separator-border-color;
                    display: block;
                    padding: $indent__s $layout-indent__width;
                }

                .switcher-trigger strong {
                    padding: $indent__s 0;
                }

                .switcher-option a {
                    padding: 0;
                }
            }
        }
    }
}

@include max-screen($screen__s) {
    .logo {
        margin-bottom: 7px;
        margin-top: 2px;
    }

    .block-search,
    .minicart-wrapper {
        margin-top: 0;
    }
}

@include max-screen($screen__xs) {
    .header.content {
        padding-top: 0;
    }

    .nav-toggle {
        top: 4px;
    }

    .logo {
        margin-bottom: 13px;
        margin-top: 9px;
    }

    .block-search {
        margin-top: 10px;

        .label {
            &::before {
                font-size: 35px;
                margin: 0;
            }
        }
    }

    .minicart-wrapper {
        margin-top: 10px;

        .action.showcart {
            &::before {
                width: 28px;
                height: 32px;
                background-size: 28px 32px;
            }
        }
    }
}

//
//  Common
//  _____________________________________________

//
//  Widgets & Blocks
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        margin-bottom: $indent__xl;
    }

    .block {
        background: $c-tomassen-white;
        padding: 10px;
        border: 1px solid $c-tomassen-grey-light;

        .block-title {
            @include lib-special-heading($c-tomassen-blue, $font-size__l);
        }
    }
}

.widget {
    clear: both;

    //to to: check
    .block-title {
        @extend .abs-block-title;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; //  Stretch screen area for sticky footer
    }

    body {
        .ie9 & {
            background-color: $copyright__background-color;
        }
    }

    .navigation ul {
        padding: 0 20px;
    }

    .page-header {
        margin-bottom: 0;

        .panel.wrapper {
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding: 5px 0 15px;
        }


        .switcher {
            float: right;
            margin-left: 15px;
            margin-right: -6px;
            order: 1;
        }
    }

    .page-main {
        & > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
            $_toggle-selector                    : ".action.switch",
            $_options-selector                   : "ul",
            $_dropdown-actions-padding           : 0,
            $_dropdown-list-item-padding         : 0,
            $_dropdown-toggle-icon-content       : $icon-down,
            $_dropdown-toggle-active-icon-content: $icon-up,
            $_icon-font-text-hide                : true,
            $_icon-font-size                     : 10px,
            $_icon-font-line-height              : 22px,
            $_dropdown-list-pointer-position     : right,
            $_dropdown-list-position-right       : 0,
            $_dropdown-list-z-index              : $customer-welcome__z-index
        );
        display: inline-block;
        li {
            // TO DO - check - sass err:
            // can't extend nested selectors
            // commented for now
            // @extend .switcher li;
            a {
                @include lib-link(
                    $_link-color                  : $color-gray20,
                    $_link-text-decoration        : none,
                    $_link-color-visited          : $color-gray20,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover            : $color-gray20,
                    $_link-text-decoration-hover  : none,
                    $_link-color-active           : $color-gray20,
                    $_link-text-decoration-active : none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
            color: $color-white;
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .header {
        &.panel {
            & > .header.links {
                @include lib-list-inline();
                float: right;
                margin-left: auto;

                & > li {
                    margin: 0 0 0 15px;

                    &.welcome,
                    & > a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            color: $color-white;
                            padding-left: $indent__xs;
                        }
                    }
                }

                & > .customer-welcome + .authorization-link {
                    display: none;
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: 10px $indent__base 0;
        }

    }

    .logo {
        margin: -30px auto $indent__s 0;

        img {
            max-height: inherit;
            height: 73px;
        }
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0;
        min-height: 100%; // Stretch content area for sticky footer
        position: relative;
        transition: margin 0.3s ease-out;

        & > .breadcrumbs,
        & > .top-container,
        & > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        & > .breadcrumbs {
            display: block;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .page-footer {
        background: $footer__background-color;
        margin-top: auto;
        padding-bottom: $indent__m;
    }

    .footer {
        &.content {

            .block {
                float: right;
            }

            ul {
            }

            .switcher.store {
                display: block;
                margin: 0;
            }

            .links {


                li {
                }

                a,
                strong {

                }
            }
        }
    }
}

//
//  Common
//  _____________________________________________


.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker td {
    padding: 0;
}

.ui-tooltip {
    border: $tooltip__border-width solid $tooltip__border-color;
    background: $tooltip__background;
    padding: 10px;
}



//Contact page styling
.contact-opening {
    margin: 0 0 20px;
    padding: 20px;
    background: $c-tomassen-white;
    box-sizing: border-box;

    h3 {
        @include lib-special-heading($c-tomassen-blue, $font-size__l);
    }

    .contact-address {
        margin-bottom: 30px;
    }

    table > thead > tr > th,
    table > thead > tr > td,
    table > tbody > tr > th,
    table > tbody > tr > td,
    table > tfoot > tr > th,
    table > tfoot > tr > td {
        padding: 3px 5px;
    }

    @include min-screen($screen__l) {
        width: 40%;
        margin-right: 40px;
        float: left;
    }
}

.contact-map {
    margin: 0 0 20px;

    @include min-screen($screen__l) {
        width: calc(60% - 40px);
        float: right;
        clear: right;
    }

    iframe {
        border: none;

        @include min-screen($screen__l) {
            min-height: 475px;
        }
    }
}

.contact-message {
    h3 {
        @include lib-special-heading($c-tomassen-blue, $font-size__xl);
    }
}

.contact-index-index {
    .fieldset {
        > .legend,
        .note {
            display: none;
        }

        > .field {
            margin-bottom: 15px;
        }

        > .field:not(.choice) > .label {
            float: none;
            display: block;
            text-align: left;
            width: 100%;
            padding: 0;
            margin: 0 0 5px;
        }
    }

    form .actions-toolbar {
        margin: 0 !important;
    }
}
